@pictext-section-first-pt: 20px;
@pictext-section-last-pb: 20px;

@pictext-pic-minh: 420px;

@pictext-text-bgc: #fff;
@pictext-text-pt: 30px;
@pictext-text-pb: 30px;
@pictext-text-pl: 30px;
@pictext-text-pr: 30px;

@pictext-inner-pt: 0px;
@pictext-inner-pb: 0px;

@pictext-text-pt-res: 16px;
@pictext-text-pb-res: 16px;
@pictext-text-pl-res: 16px;
@pictext-text-pr-res: 16px;

.pictext {
  
  &__item > .row {
    overflow: hidden;
  }

  &__wrapper {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    &.pic {

      position: relative;

      display: block;

      height: 100%;
      min-height: @pictext-pic-minh;

    }
    &.text {

      background-color: @pictext-text-bgc;

      padding-top: @pictext-text-pt;
      padding-bottom: @pictext-text-pb;
      padding-left: @pictext-text-pl;
      padding-right: @pictext-text-pr;

      @media (max-width: 1199px) {
        padding-left: @pictext-text-pl-res;
        padding-right: @pictext-text-pr-res;
      }

      & .content {

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6,
        & p, & .p, & ul, & ol, & span {

          align-self: stretch;

        }

      }

      &.template {
        background-color: rgba(255,255,255,0.4);
        z-index: 11;
        position: relative;
      }

    }
  }

  &__inner {

    height: 100%;
    position: relative;
    &.video{
      display: flex;
      & #bgvid {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  &__img {

    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.contain {
      background-size: 95%;
    }

    &--abs {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.35);
      z-index: 97;
    }
    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
      font-size: 55px;
      line-height: 1.3;
      text-shadow: 1px 1px 2px #000;
      font-family: @alternative-font;
      width: 100%;
      letter-spacing: 2px;
      z-index: 98;
      @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 1.1;
      }
    }
  }

  &-section {
    
    position: relative;

    padding-top: @pictext-inner-pt;
    padding-bottom: @pictext-inner-pb;

    &:first-child, &:first-of-type {
      
      padding-top: @pictext-section-first-pt;

    }

    &:last-child, &:last-of-type {
      
      padding-bottom: @pictext-section-last-pb;

    }

    &.first {
      padding-bottom: 50px;
    }

    &.left {
      & .pictext__order_pic {
        order: 1;
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictext__order_text {
        order: 2;
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
    &.right {
      & .pictext__order_pic {
        order: 2;
        @media (max-width: 767px) {
          order: unset;
        }
      }
      & .pictext__order_text {
        order: 1;
        @media (max-width: 767px) {
          order: unset;
        }
      }
    }
  }
}